import { useContext } from 'react'
import { RouterContext } from '../Router'
import { observer } from 'mobx-react'
import { useMst } from 'app/store'
import { displayNameFromRigType } from '../../../utils/helpers'


const StatusSelect = observer(({status, setStatus}) => {
  const customStyles = {backgroundColor: status === "completed" ? 'rgba(26, 222, 143, 0.2)' : 'rgba(232, 199, 82, 0.2)', color: status === "completed" ? '#1ADE8F' : '#E8C752', textTransform: 'uppercase',fontWeight: 600, border: 'none',padding: '4px 6px',marginLeft: '8px', borderRadius: '8px', fontSize: '14px',lineHeight: '28px', textAlign: 'center'};
    
  return (
    <select
          id="wellStatus"
          className="wellStatusSelect"
          value={status}
          onChange={setStatus}
          style={customStyles}
        >
          <option key="inprogress" value="inprogress">In Progress</option>
          <option key="completed" value="completed">Completed</option>
    </select>
  )
})

const Badges =  observer (({test}) => {
  const {
    store: { WellM },
  } = useMst()
  const { locationState } = useContext(RouterContext)
  

  return (
      <div className="textGeneric wellSectionHeaderStatus">
      <span className="uiContainerStatusMessage">
        {displayNameFromRigType(locationState?.rigType, false)}
      </span>
      <span className="uiContainerStatusWarning" style={{ marginLeft: '8px' }}>
        {locationState.units}
      </span>
      <span>
        <StatusSelect 
          status={WellM?.Status}
          setStatus={(e) => WellM?.updateWellData(WellM?.id, 'Status', 'string', e.target.value)}
        />
      </span>
    </div>
  );
})

export default Badges