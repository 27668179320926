import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import { useState, useContext, useEffect, useCallback } from 'react'
import { RouterContext } from '../Router'
import addPartIcon from 'app/components/kit/icons/PlusCircleBlue.svg'
import flipBHAIcon from '../../../assets/images/icons/icon_flip.svg'
import trashIcon from '../../../assets/images/icons/icon_trash.svg'
import trashIconGray from '../../../assets/images/icons/icon_trashGray.svg'
import editPart from '../../../assets/images/icons/icon_edit.svg'
import Tower from './Tower'
import AddPart from './AddPart'
import EditPart from './EditPart'
import { Button } from '../kit/SmallWidthButton'
import { SectionTabs } from '../WellSection/SectionTabs'
import { getLabel, getLabelNoBrazilian, getStatistic, unitConversion } from '../../../utils/helpers'
import Select from '../kit/Select'
import { getNextGroupNumber } from 'app/components/BHA/AddPart'
import { undoManager } from 'app/store'
import { UserContext } from 'App'
import './index.css'


const BHA = observer(() => {
  const { user } = useContext(UserContext)
  const {
    store: { BHA },
  } = useMst()
  const [expandedAdd, setExpandedAdd] = useState(false)
  const [expandedEdit, setExpandedEdit] = useState(false)
  const [editedPart, setEditedPart] = useState('')
  const [flipped, setFlipped] = useState(false)
  const [historyIndex, setHistoryIndex] = useState(0);
  const { locationState } = useContext(RouterContext)
  
  const [isAllChecked, setAllChecked] = useState(false);

  const [colorShearable, setColorShearable] = useState(false);
  
  const newCheckedArray = useCallback((isChecked) => {
    return new Array(BHA && BHA.parts ? BHA.parts.length : 0).fill(isChecked)
  }, [BHA])

  const [isChecked, setChecked] = useState(
    newCheckedArray(false)
  );
 
  const checkedReset = useCallback((isChecked) => {
    setChecked(newCheckedArray(isChecked))
  }, [newCheckedArray])

  //update allChecked checkbox when individual checkboxes change
  useEffect(() => {
    setAllChecked(isChecked.length > 0 && !isChecked.includes(false));
  }, [isChecked])

  //reset if BHA parts length changed
  useEffect(() => {
    if(BHA && BHA.parts && isChecked.length !== BHA.parts.length) {
      checkedReset(false);
    }},
    [BHA, isChecked.length, checkedReset]
  )

  const renumerateGroups = () => {
    let currentGroupNumber = -1;
    let previousGroupNumber = undefined;
    
    for(let i = 0; i < BHA.parts.length; i++) {
      const thisGroupNumber = BHA.parts[i].groupNumber;
      if(thisGroupNumber !== undefined) {
        if(thisGroupNumber !== previousGroupNumber) {
          currentGroupNumber++;
          previousGroupNumber = thisGroupNumber;
        }
        if(thisGroupNumber !== currentGroupNumber) 
          BHA.parts[i].updateGroupNumber(currentGroupNumber);
      }
    } 
  }

  const selectUnselectAll = () => {
    checkedReset(!isAllChecked);
  }

  const selectUnselectRow = (i) => {
    //set of selected group numbers for creating a group
    const isTargetIndex = new Set([i]);
    const targetGroup = BHA.parts[i].groupNumber;

    if(targetGroup !== undefined) {
      for(let i = 0; i < BHA.parts.length; i++) {
        if(BHA.parts[i].groupNumber === targetGroup){
          isTargetIndex.add(i); 
        }
      }  
    }

    const updatedCheckedState = isChecked.map((item, index) => {
      //set checked for all rows with the same index
      return isTargetIndex.has(index) ? !item : item;
    });

    setChecked(updatedCheckedState);
  }
  
  const clickOnGroup = () => {
    undoManager.startGroup(() => {

      let targetGroupNumber = undefined; 
    
      const numberChecked = isChecked.reduce((accum, val) => val ? accum + 1 : accum, 0);
      
      //if only one row is selected don't create a group
      if(numberChecked < 2) return;
     
    
      for(let i = 0; i < isChecked.length; i++) {
        if(isChecked[i]) {
          if(BHA.parts[i].groupNumber !== undefined) {
            targetGroupNumber = BHA.parts[i].groupNumber;
            break;
          }
        }
      }
      // State Machine to check consecutive rows:
      // wait - waiting for first checkmark
      // inside - inside a checked range
      // done - outside checked range
      // fail - found a checkmark outside first checked range
      let state = 'wait';
  
      for(let i = 0; i < isChecked.length; i++) {
       if(isChecked[i]) {
        switch(state) {
          case 'wait':
            state = 'inside';
            break;
          case 'done':
            state = 'fail';
            break;
          default:
            break;
        }
       } else {
        switch(state) {
          case 'inside':
            state = 'done'
            break;
          default:
            break;
        }
       }
      }
  
      if(state === 'fail') return;
    
      const targetUndefined = targetGroupNumber === undefined; 
      if(targetUndefined) {
        targetGroupNumber = getNextGroupNumber(BHA);
      }
      
      isChecked.forEach((checked, idx) => {       
        if(checked) {
            BHA.parts[idx].updateGroupNumber(targetGroupNumber)
          }
        }
      )
      if(!targetUndefined) renumerateGroups()
      checkedReset(false)
    })
    undoManager.stopGroup();
  }

  const clickOnUngroup = () => {
    
    undoManager.startGroup(() => {
      
      const setGroupNumber = new Set([]);

      for(let i = 0; i < isChecked.length; i++ ) {
        if(isChecked[i]) {
          setGroupNumber.add(BHA.parts[i].groupNumber);
        }
      }
      
      for(let i = 0; i < BHA.parts.length; i++){
        if(BHA.parts[i].groupNumber !== undefined){
          if(setGroupNumber.has(BHA.parts[i].groupNumber)) {
            BHA.parts[i].updateGroupNumber(undefined)
            checkedReset(false)        
          }
        }
      }
      renumerateGroups();
    })
    undoManager.stopGroup();
  } 
  
  const deletePart = (partIndexInBHA, groupNumber) => {
    undoManager.startGroup(() => {
      removeOnePartGroup(groupNumber); 
      BHA.deletePart(partIndexInBHA);
      const newIsChecked = isChecked.slice()
      newIsChecked.splice(partIndexInBHA, 1)
      setChecked(newIsChecked);
    })
    undoManager.stopGroup(); 
  }

  const removeOnePartGroup = (groupNumber) => {
    if(groupNumber === undefined) return;

    const thisGroupParts = BHA.parts.filter((part) => part.groupNumber === groupNumber)

    if(thisGroupParts.length < 3) {
      thisGroupParts.forEach((part) => part.updateGroupNumber(undefined));
      renumerateGroups();
    }
  }

   
  const isSupport = JSON.stringify(['rig', 'company', 'global'])
        === JSON.stringify(user?.scope)

  return (
    <>
      <SectionTabs />
      <div id="bhaHistory" style={{ display: 'flex', justifyContent: 'space-between', gap: 16 }}>
        <Select
          defaultValue={historyIndex}
          title="History entry"
          id='historyEntrySelection'
          onChange={(e) => setHistoryIndex(Number(e.target.value))}
        >
          <option key="Current" value="0">
             Current
          </option>
          {BHA?.history?.toReversed().map((entry, idx ) => {
            const originalIdxPlusOne = BHA?.history?.length - idx;
            return  (
              <option key={"History" + originalIdxPlusOne} value={originalIdxPlusOne}>
                BHA {originalIdxPlusOne}
              </option>
            )
          })}
        </Select>
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 16, marginBottom: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', height: '41px', fontSize: '14px' }}>
            <label
              htmlFor={'color-shearable-input'}
              style={{ color: '#fff', textWrap: 'nowrap' }}
            >
              <input
                id="color-shearable-input"
                type="checkbox"
                checked={colorShearable}
                onChange={(e) => {setColorShearable(e.target.checked)}}
              />
              <span style={{ 
                verticalAlign: "middle", 
                cursor: "pointer", 
                paddingLeft: "4px", 
                userSelect: "none",
                textWrap: "nowrap"
              }}>Color shearable/unshearable</span>
            </label>
          </div>
          {historyIndex === 0 ? (
          <>
            <Button small 
              id="bhaGroupButton" 
              onClick={() => clickOnGroup()}
              >
              Group
            </Button>
            <Button
              small 
              id="bhaUngroupButton" 
              onClick={() => clickOnUngroup()}
            >
              Ungroup
            </Button>
            <Button small id="bhaSaveToHistoryButton" 
              onClick={() => {BHA?.pushHistory()}}>
              Save to history
            </Button>
          </>) : null}
          {isSupport ? (
            <Button small id="bhaRemoveFromHistoryButton" 
              onClick={() => { setHistoryIndex(0); BHA?.clearHistory(historyIndex) }}>
              Remove {historyIndex === 0 ? "" : "BHA " + (historyIndex) + " from "}history
            </Button>
          ) : null}
        </div>
      </div>
      <div id="bha" style={{ display: 'flex', justifyContent: 'start', gap: '8px' }}>
        <AddPart
          open={expandedAdd}
          isChecked={isChecked}
          setChecked={setChecked}
          onClose={() => {
            setExpandedAdd(false)
          }}
         />
        <EditPart
          open={expandedEdit}
          partId={editedPart}
          partType={BHA?.tally(historyIndex).find(part => part.id === editedPart)?.partType}
          historyIndex={historyIndex}
          partDescription={BHA?.tally(historyIndex).find(part => part.id === editedPart)?.description}
          onClose={() => {
            setExpandedEdit(false)
            setEditedPart('')
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          {historyIndex === 0 ? <Button onClick={() => {setExpandedAdd(!expandedAdd)}} id={'addOrCreateBHAbutton'} title='Add part'>
            <img alt="add part" src={addPartIcon}/>
          </Button> : null}
          <Button onClick={() => {setFlipped(!flipped)}} id={'flipBHAbutton'} title='Flip BHA'>
            <img alt="flip BHA" src={flipBHAIcon}/>
          </Button>
         <Tower flipped={flipped} historyIndex={historyIndex}/>
        </div>
        <div className="bhaTableWrapper">
          <table className={`bhaTable ${historyIndex === 0 ? "bhaCurrentTable" : "bhaHistoricTable"}`}>
            <thead style={{ display: 'table-header-group' }}>
              <tr>
                {historyIndex === 0 ? (
                  <th>
                    <input
                      id="inputCheckAll"
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={selectUnselectAll}
                    />
                  </th>
                ) : null}
              
                {historyIndex === 0 ? (<th style={{ width: 42 }}><img alt="delete part" src={trashIconGray} /></th>) : <></>}
                <th style={{ width: 15 }}>{historyIndex === 0 ? <>EDIT</>:<>VIEW</>}</th>
                <th style={{ width: 27 }}></th>
                <th style={{ width: 120 }}>Desc.
                  <span id="shearableLegend">
                    <br/><span style={{ whiteSpace: 'nowrap' }}>(<span style={{ verticalAlign: 'middle', fontSize: 18 }}>*</span> shearable)</span>
                  </span>
                </th>
                {/* <th style={{}}>Serial No.</th> */}
                <th style={{ width: 84 }}>
                  Top&nbsp;Type<br/>
                  Bottom&nbsp;Type
                </th>
                <th style={{ width: 54, whiteSpace: 'nowrap' }}>
                  Max&nbsp;OD (in)<br/> 
                  ID&nbsp;(in)
                </th>
                <th style={{ width: 59, whiteSpace: 'nowrap' }}>F/N<br/>OD&nbsp;(in)<br/>Length&nbsp;({getLabel('lengthMedium', locationState)})</th>
                <th style={{ width: 57, whiteSpace: 'nowrap' }}>
                  Weight ({getLabel('weightDistance', locationState)})<br/>
                  Part&nbsp;Weight ({getLabelNoBrazilian('weight', locationState)})
                </th>
                <th style={{ width: 76 }}>Total Weight ({getLabelNoBrazilian('weight', locationState)})</th>
                <th style={{ width: 58, whiteSpace: 'nowrap' }}>
                  Disp. ({getLabel('volumeDistance', locationState)})<br/>
                  Cap. ({getLabel('volumeDistance', locationState)})
                </th>
                <th style={{ width: 55 }}>Length ({getLabel('lengthMedium', locationState)})</th>
                <th style={{ width: 60 }}>Total Length ({getLabel('lengthMedium', locationState)})</th>
                <th style={{ width: 59 }}>Lift&nbsp;Sub<br/>TJ&nbsp;O.D.</th>
                <th style={{ width: 59 }}>Elevator/<br/>Insert&nbsp;I.D.</th>
                <th style={{ width: 59 }}>Elev.&nbsp;Marking/<br/>Colour&nbsp;Code</th>
                <th style={{ width: 59 }}>Slip/<br/>Insert&nbsp;Size</th>
                <th style={{ minWidth: 120 }}>Comment</th>
              </tr>
            </thead>
              {(() => {
                const rowArray = [];
                const tally = BHA?.tally(historyIndex)
                
                //check previous and next group number to add border style for the first, middle, and last group rows
                let prevGroupNumber = undefined;

                for(let i = flipped ? tally.length - 1 : 0; 
                  flipped ? i >= 0 : i < tally.length; 
                  flipped ? i-- : i++) {
                    const part = tally[i];
                    const partIndexInBHA = i;
                    
                    const nextGroupNumber = flipped ?
                      (i > 0 ? tally[i-1].groupNumber : undefined)
                      : (i < tally.length - 1 ? tally[i+1].groupNumber : undefined);
                    
                    const groupClass =
                        part.groupNumber === undefined ? ""
                        : prevGroupNumber === nextGroupNumber ? "bha-group-middle" 
                        : prevGroupNumber === part.groupNumber ? "bha-group-bottom" : "bha-group-top"

                    rowArray.push((
                      <tbody 
                        key={i} 
                        className={
                          `${colorShearable ? (part.shearable ? "shearable" : "unshearable") : ""} 
                          ${groupClass}` 
                          }
                        >
                          
                        <tr>
                        { historyIndex === 0 ? (
                        <td rowSpan={2}>
                          <input
                            key={i} 
                            id={i}
                            type="checkbox"
                            checked={isChecked[partIndexInBHA] || false}
                            onChange={() => selectUnselectRow(partIndexInBHA)}
                          />
                        </td>
                        ) : null}
                          
                        {  historyIndex === 0 ? (
                          <td style={{ width: 42 }} rowSpan={2}>
                            <button
                              className="icon-button trash-icon"
                              onClick={() => {deletePart(partIndexInBHA, part.groupNumber)}}
                              title='Delete part'
                              style={{ margin: 'auto' }}
                            >
                            <img alt="delete part" src={trashIcon} />
                            </button>
                          </td>
                        ) : <></>
                          }
                          <td style={{ width: 15 }} rowSpan={2}>
                              <button
                                className="small-icon-button edit-icon"
                                onClick={() => {
                                  setEditedPart(part.id)
                                  setExpandedEdit(true)
                                }}
                                title={historyIndex === 0 ? 'Edit part' : 'View part details'}
                                style={{ width: "24px", height: "24px", margin: 'auto' }}
                              >
                                <img style={{ paddingLeft: '3px' }} alt={historyIndex === 0 ? "edit part" : "view part details"} src={editPart} />
                              </button>
                          </td>
                          <td style={{ width: 27 }} rowSpan={2}>
                            {tally.length - partIndexInBHA}.
                          </td>
                          <td style={{ width: 120, textAlign: 'left' }} rowSpan={2}>
                            <div style={{ position: 'relative'}}>
                              <span title={part.description}>
                                {part.description.length > 30 ? part.description.substring(0, 30) + '...' : part.description}
                              </span><br />
                              <span title={part.serialNumber} style={{ color: 'dodgerblue' }}>
                                {part.serialNumber.length > 30 ? part.serialNumber.substring(0, 30) + '...' : part.serialNumber}
                              </span>
                              <span className="shearableIndicator">*</span>
                            </div>
                          </td>
                          <td style={{ width: 84 }} className="topInSplit">
                            {part.topType}
                          </td>
                          <td style={{ width: 54 }} className="topInSplit">
                            {part.maxOD}"
                          </td>
                          <td style={{ width: 59 }} className="topInSplit">
                            {part.OD}"
                          </td>
                          <td style={{ width: 57 }} className="topInSplit">
                            {unitConversion('lengthMedium', locationState?.units, 'in', Number(part.weight), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 76 }} rowSpan={2}>
                            {unitConversion('noHybridConversion', locationState?.units, 'in', Number(part.totalWeight), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 58 }} className="topInSplit">
                            {unitConversion('lengthMedium', locationState?.units, 'in', Number(part.weight) / getStatistic('bhaDispMultiplierBha', locationState), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 55 }} rowSpan={2}>
                            {unitConversion('lengthMedium', locationState?.units, 'out', Number(part.length), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 60 }} rowSpan={2}>
                            {unitConversion('lengthMedium', locationState?.units, 'out', parseFloat(part.totalLength), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 59 }} rowSpan={2}>
                            {part.liftSubTJOD ? part.liftSubTJOD : ''}
                          </td>
                          <td style={{ width: 59 }} rowSpan={2}>
                            {part.elevatorID ? part.elevatorID : ''}
                          </td>
                          <td style={{ width: 59 }} rowSpan={2}>
                            {part.elevatorMarking ? part.elevatorMarking : ''}
                          </td>
                          <td style={{ width: 59 }} rowSpan={2}>
                            {part.insertSize ? part.insertSize : ''}
                          </td>
                          <td style={{ width: 120, textAlign: 'left', verticalAlign: 'top' }} rowSpan={2}>
                            <div className='bhaPartComment'>
                              {part.comment ? part.comment : ''}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: 84 }}>
                            {part.botType}
                          </td>
                          <td style={{ width: 54 }}>
                            {part.ID}"
                          </td>
                          <td style={{ width: 59 }}>
                            {part.fishneckLength === undefined || part.fishneckLength === '' ? '' : unitConversion('lengthMedium', locationState?.units, 'out', Number(part.fishneckLength), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 57 }}>
                            {unitConversion('noHybridConversion', locationState?.units, 'in', Number(part.itemWeight), 15).toFixed(2)}
                          </td>
                          <td style={{ width: 58 }}>
                            {((part.ID * part.ID) / getStatistic('bhaCapMultiplierBha', locationState)).toFixed(3)}
                          </td>
                        </tr>
                      </tbody>
                    ));

                    prevGroupNumber = part.groupNumber;
                }
                
                return rowArray;

              })()}
          </table>
        </div>
      </div>
    </>
  )
})

export default BHA
