import { PartTypesEnum } from 'app/models/BHA/BHAPart'
import { useMst } from 'app/store'
import { observer } from 'mobx-react'
import {
  ChangeEventHandler,
  useState,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import { RigContext } from 'App'
import { RouterContext } from '../Router'
import Dialog from '../kit/Dialog'
import Input from '../kit/Input'
import Select from '../kit/Select'
import { Button } from '../kit/SmallWidthButton'
import { getLabel, unitConversion } from '../../../utils/helpers'
import CopyBha from './CopyBha'
import { useLazyQuery, gql } from '@apollo/client'
import { options } from 'app/models/BHA/PartTypes'
import { BHAInstance } from 'app/models/BHA'
import { validateFields } from 'app/models/BHA/BHAPart'


const getLibraryPipes = gql`
  query getPipes($type: String!, $rigId: String!) {
    getPipes(
      type: $type,
      rigId: $rigId
    ) {
      id
      type
      name
      connection
      OD
      ID
      weight
      disp
      cap
      range
      mutMin
      mutMax
      tenStrength
      torStrength
      standard
      rigId
    }
  }
`

const initialState : {serialNumber: string, length: string, partType: PartTypesEnum,
  OD: string, fishneckLength: string, ID: string, weight: string, maxOD: string, topType: string, botType: string,
  description: string, liftSubTJOD: string, elevatorID: string, elevatorMarking: string,
  insertSize: string, comment: string, info: string, bitNumber: string, bitNozzles: string,
  bitTFA: string, bitManufacturer: string, bitDullGradeIn: string, bitDullGradeOut: string,
  shearable: boolean, pipeId: string | undefined
} = {
  serialNumber: '',
  length: '',
  partType: 'NONE' as PartTypesEnum,
  OD: '',
  fishneckLength: '',
  ID: '',
  weight: '',
  maxOD: '',
  topType: '',
  botType: '',
  description: '',
  liftSubTJOD: '',
  elevatorID: '',
  elevatorMarking: '',
  insertSize: '',
  comment: '',
  info: '',
  bitNumber: '',
  bitNozzles: '',
  bitTFA: '',
  bitManufacturer: '',
  bitDullGradeIn: '',
  bitDullGradeOut: '',
  shearable: false,
  pipeId: undefined,
}

type StateKeys = keyof typeof initialState

export const getNextGroupNumber = (BHA: BHAInstance) => {
  const maxGroupNumber = BHA?.parts.reduce((accum: any, val: any) => val.groupNumber === undefined ? 
  accum 
  : accum === undefined ? 
    val.groupNumber 
    : Math.max(val.groupNumber, accum),
  undefined);

  return maxGroupNumber === undefined ? 0 : maxGroupNumber + 1;
}

const AddPart = observer(({ open, onClose: oc, isChecked, setChecked }: AddPartProps) => {
  const {
    store: { BHA },
  } = useMst()
  const [form, setForm] = useState(initialState)
  const { locationState } = useContext(RouterContext)
  const [numberOfPipes, setNumberOfPipes] = useState(1);
  const [groupChecked, setGroupChecked] = useState(false);

  

  const [ getPipes,  {loading: pipeLoading, error: pipeError, data: pipeData} ] 
  = useLazyQuery(getLibraryPipes
  )

  const onClose = useCallback(() => {
    oc()
    setTimeout(() => {
      setForm(initialState)
      setNumberOfPipes(1)
      setGroupChecked(false)
    }, 250)
  }, [oc])

  const { rig } = useContext(RigContext)

  const handleInputs = (key: StateKeys): ChangeEventHandler<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement> =>
    function (e) {
       const newValue = e.target.value 
       switch(key) {
          case 'partType' :
            switch(newValue) {
              case 'DP':
              case 'HWDP':
              case 'DC':
                getPipes({variables: { type: newValue, rigId: rig?.id }});
                break;
              default:
                break;
            }

            // Clean up necessary fields if changing from one of pipe types
            switch(form.partType) {
              case 'DP':
              case 'HWDP':
              case 'DC':
                setForm({ ...form, [key]: newValue as PartTypesEnum, pipeId: undefined, 
                  description: '', weight: '', ID: '', OD: '', maxOD: '', topType: '', botType: '' })
                break;
              default:
                // Clean up necessary fields when changing to one of the pipe types
                if (newValue === 'DP' || newValue === 'HWDP' || newValue === 'DC') {
                  setForm({ ...form, [key]: newValue as PartTypesEnum, pipeId: undefined,
                    description: '', weight: '', ID: '', OD: '', maxOD: '', topType: '', botType: '' })
                } else {
                  setForm({ ...form, [key]: newValue as PartTypesEnum })
                }
                break;
            }
            break;
          case 'pipeId':
            if(newValue) {
              const pipe = pipeData.getPipes.find((item : any) => item.id === newValue);
              setForm({ ...form, [key]: newValue, 
                description: pipe.name, weight: pipe.weight, ID: pipe.ID, OD: pipe.OD, maxOD: pipe.OD, topType: pipe.connection, botType: pipe.connection  })  
            } else {
              setForm({ ...form, [key]: undefined, 
                description: '', weight: '', ID: '', OD: '', maxOD: '', topType: '', botType: '' })  
            }
            break;
            default:
            setForm({ ...form, [key]: newValue })
            break;
       }
    }

  const handleCheckboxes = (key: StateKeys): ChangeEventHandler<HTMLInputElement> =>
    function (e) { setForm({ ...form, [key]: e.target.checked }) }

  
  const handleGroup = () => {
    setGroupChecked(!groupChecked);
  }

  const handleNumberOfPipes = (e: React.ChangeEvent<HTMLInputElement>) => { 
    
    const newValue = parseInt(e.target.value);

    if(numberOfPipes === 1 && newValue > 1) {
      setForm({...form,
        serialNumber: ''
      })
    }
    setNumberOfPipes(newValue)
  }

  const handleSubmit = () => {
    if (form.partType !== 'NONE' && BHA) {
      const thisGroupNumber = groupChecked ? getNextGroupNumber(BHA) : undefined;
      const newIsChecked = isChecked.slice();
      for(let i = 0; i < numberOfPipes; i++) {
        const { index, deleteCount } = BHA.addPart({
          OD: Number(form.OD),
          fishneckLength: form.fishneckLength === undefined || form.fishneckLength === '' ? undefined : unitConversion('lengthMedium', locationState?.units, 'in', Number(form.fishneckLength), 15),
          ID: Number(form.ID),
          description: form.description,
          serialNumber: form.serialNumber || undefined,
          length: unitConversion('lengthMedium', locationState?.units, 'in', Number(form.length), 15),
          weight: unitConversion('lengthMedium', locationState?.units, 'out', Number(form.weight), 15),  // length is ok, only going lbs/m to lbs/ft
          maxOD: Number(form.maxOD),
          partType: form.partType,
          topType: form.topType,
          botType: form.botType,
          liftSubTJOD: form.liftSubTJOD ? Number(form.liftSubTJOD) : undefined,
          elevatorID: form.elevatorID ? Number(form.elevatorID) : undefined,
          elevatorMarking: form.elevatorMarking,
          insertSize: form.insertSize ? Number(form.insertSize) : undefined,
          comment: form.comment,
          info: form.info,
          bitNumber: form.bitNumber,
          bitNozzles: form.bitNozzles,
          bitTFA: form.bitTFA,
          bitManufacturer: form.bitManufacturer,
          bitDullGradeIn: form.bitDullGradeIn,
          bitDullGradeOut: form.bitDullGradeOut,
          shearable: form.shearable,
          pipeId: form.pipeId,
          groupNumber: thisGroupNumber,
        })
        if (deleteCount === 0) {
          newIsChecked.splice(index, deleteCount, false);
        } // if a replacement occurred (deleteCount is not 0), then don't change the checked state
      }
      setChecked(newIsChecked);
    }
    onClose()
  }

  const submitDisabled = form.partType === 'NONE' // part type must be chosen
  || !validateFields(form) // all fields must be valid
  || ((form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC') && (!form.pipeId || isNaN(numberOfPipes))) 
  // when using a pipe type, pipe must be selected and number of pipes must be defined

  return (
    <Dialog
      style={{ padding: '8px', maxWidth: 725, border: '1px solid #29465B' }}
      open={open}
      onClose={onClose}
    >
      {BHA?.parts.length === 0 && form.partType === 'NONE' ? (
        <CopyBha onClose={onClose} />
      ) : null}
      <div style={{ backgroundColor: '#5B5C7455', borderRadius: '16px', padding: '10px', textAlign: 'center' }}>
        <div>
          {
            (BHA?.parts.length || 0) === 0 ? (
            <h4 style={{ textAlign: 'left', padding: '0px 10px 10px 0px', width: '100%' }}>
              <span className="uiContainerStatusWarning" style={{ marginRight: '15px' }}>CREATE &nbsp; BHA</span>
              <span style={{ fontWeight: 500, color: '#E5E4E2' }}>I wish to add a new joint to begin to <span style={{ color: 'cornflowerblue', textDecoration: 'none', fontWeight: 900, marginLeft: '2px', marginRight: '2px' }}>create</span> a new BHA from scratch</span>
            </h4>
            ) : null
          }
          <h3 style={{
            color: 'lightslategray',
            textAlign: 'center',
            width: '100%',
            margin: '18px 0px 0px 0px'
          }}>Add BHA Part</h3>
          <div style={{
            margin: '10px auto 5px auto',
            width: '200px',
            color: '#FFF', 
            fontWeight: 800 
          }}>
            <Select
              id="currentPipeType"
              value={form.partType}
              title="Part Type"
              style={{ textAlign: 'center', width: '200px', margin: 'auto' }}
              onChange={handleInputs('partType')}
            >
              {options.map(({ value, name }) => {
                return value === 'String' ? (
                  <option key={value} value="NONE">
                    {name}
                  </option>
                ) : (
                  <option key={value} value={value}>
                    {name}
                  </option>
                )
              })}
            </Select>
          </div>
          {(form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC') && !pipeLoading && !pipeError ? (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap:  '0.5rem', gridArea: '1/1' }}>
              <Input
                style={{ width: '100%' }}
                id="parts-number"
                title="Number of Pipes*"
                type="number"
                min="1"
                step="1"
                value={numberOfPipes}
                onChange={handleNumberOfPipes}
                className="inputModalField"
                labelClassName="placeholder"
                wrapperClassName="field"
                required
              />
           </div>
            <div style={{ textAlign: 'center', width: '100%', margin: 'auto', gridArea: '1/2' }}>
              <Select
                id="currentPipeId"
                title="Select Pipe"
                value={form.pipeId ? form.pipeId : ''}
                onChange={handleInputs('pipeId')}
                style={{ textAlign: 'center', width: '100%', margin: 'auto' }}
              >
                <option key='' value=''>-- Select Pipe --</option>
                  {pipeData.getPipes.map((item : any) => { return (
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
              )}
             </Select>
            </div>
       
           <div style={{ display: 'flex', alignItems: 'center', gap:  '0.5rem', gridArea: '1/3', margin: '28px 0 5px 0'  }}>
            <Input
              style={{ margin: "0", color: '#fff', display: isNaN(numberOfPipes) || numberOfPipes < 2 ? 'none' : 'flex'}}
              id="parts-number"
              title="Group"
              type="checkbox"
              checked={groupChecked}
              onChange={handleGroup}
            />
           </div>
             </div>
          ) : (null)}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            columnGap: '2%',
          }}
        > 
            {form.partType !== 'NONE' ? (
              <Fragment>
                <Input
                  style={{ width: '32%' }}
                  id="part-serial-number-input"
                  title="Serial number"
                  type="text"
                  value={form.serialNumber}
                  onChange={handleInputs('serialNumber')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={numberOfPipes > 1}
                  inputStyle={{ cursor: numberOfPipes > 1 ? 'not-allowed' : 'default' }}
                />
                <Input
                  style={{ width: '66%' }}
                  id="part-desc-input"
                  title="Description*"
                  type="text"
                  value={form.description}
                  onChange={handleInputs('description')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                   />
                <Input
                  style={{ width: '32%' }}
                  id="part-length-input"
                  title={"Length* (" + getLabel('lengthMedium', locationState) + ")"}
                  type="number"
                  value={form.length}
                  onChange={handleInputs('length')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-weight-input"
                  title={"Weight/Length* (" + getLabel('weightDistance', locationState) + ")"}
                  type="number"
                  value={form.weight}
                  onChange={handleInputs('weight')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-od-input"
                  title="F/N OD*"
                  type="number"
                  value={form.OD}
                  onChange={handleInputs('OD')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-fishneck-length-input"
                  title="F/N Length"
                  type="number"
                  defaultValue={form.fishneckLength}
                  onChange={handleInputs('fishneckLength')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-id-input"
                  title="ID*"
                  type="number"
                  value={form.ID}
                  onChange={handleInputs('ID')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-max-od-input"
                  title="Max OD*"
                  type="number"
                  value={form.maxOD}
                  onChange={handleInputs('maxOD')}
                  step="0.1"
                  min="0"
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-top-type-input"
                  title="Top Type*"
                  type="text"
                  value={form.topType}
                  onChange={handleInputs('topType')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-bot-type-input"
                  title="Bottom Type*"
                  type="text"
                  value={form.botType}
                  onChange={handleInputs('botType')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                  disabled={form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC'}
                  inputStyle={{ cursor: form.partType === 'DP' || form.partType === 'HWDP' || form.partType === 'DC' ? 'not-allowed' : 'default' }}
                  required
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-liftsub-input"
                  title="Lift Sub TJ O.D."
                  type="text"
                  value={form.liftSubTJOD}
                  onChange={handleInputs('liftSubTJOD')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-elevatorid-input"
                  title="Elevator/Insert I.D."
                  type="text"
                  value={form.elevatorID}
                  onChange={handleInputs('elevatorID')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-elevatormarking-input"
                  title="Elev. Marking/Colour Code"
                  type="text"
                  value={form.elevatorMarking}
                  onChange={handleInputs('elevatorMarking')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                    />
                <Input
                  style={{ width: '32%' }}
                  id="part-insertsize-input"
                  title="Slip/Insert Size"
                  type="text"
                  value={form.insertSize}
                  onChange={handleInputs('insertSize')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  style={{ width: '100%' }}
                  id="part-comment-input"
                  title="Comment"
                  type="text"
                  value={form.comment}
                  onChange={handleInputs('comment')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                    />
                {(form.partType === 'PDC' || form.partType === 'RollerCone') ? (
                  <Fragment>
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-number-input"
                      title="Bit Number"
                      type="text"
                      value={form.bitNumber}
                      onChange={handleInputs('bitNumber')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-nozzles-input"
                      title="Nozzles"
                      type="text"
                      value={form.bitNozzles}
                      onChange={handleInputs('bitNozzles')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-tfa-input"
                      title="TFA"
                      type="text"
                      value={form.bitTFA}
                      onChange={handleInputs('bitTFA')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-manufacturer-input"
                      title="Manufacturer"
                      type="text"
                      value={form.bitManufacturer}
                      onChange={handleInputs('bitManufacturer')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-dull-grade-in-input"
                      title="Dull Grade In"
                      type="text"
                      value={form.bitDullGradeIn}
                      onChange={handleInputs('bitDullGradeIn')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                    <Input
                      style={{ width: '32%' }}
                      id="part-bit-dull-grade-out-input"
                      title="Dull Grade Out"
                      type="text"
                      value={form.bitDullGradeOut}
                      onChange={handleInputs('bitDullGradeOut')}
                      className="inputModalField"
                      labelClassName="placeholder"
                      wrapperClassName="field"
                    />
                  </Fragment>
                ) : (null)}
                <div className={'field'} style={{ width: '100%' }}>
                  <textarea
                    id={'part-info-input'}
                    className={'inputModalField'}
                    style={{ width: '100%', resize: 'none'}}
                    value={form.info}
                    placeholder={''}
                    onChange={handleInputs('info')}
                    rows={2}
                  />
                  <label
                    htmlFor={'part-info-input'}
                    className={'placeholder'}
                    style={{ pointerEvents: 'none' }}
                  >
                    {'Information'}
                  </label>
                </div>
              </Fragment>
            ) : null
            }
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: form.partType !== 'NONE' ? 'space-between' : 'flex-end',
            marginTop: '15px'
          }}
        >
          {
            form.partType !== 'NONE' ? 
            <div style={{ 
              fontSize: "14px",
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
            }}>
              <label
                htmlFor={'part-shearable-input'}
                style={{ color: '#fff' }}
              >
                <input
                  id="part-shearable-input"
                  title="Shearable"
                  type="checkbox"
                  checked={form.shearable}
                  style={{ margin: "0" }}
                  onChange={handleCheckboxes('shearable')}
                />
                <span style={{ 
                  verticalAlign: "middle", 
                  cursor: "pointer", 
                  paddingLeft: "4px", 
                  userSelect: "none" 
                }}>Shearable</span>
              </label>
            </div> : null 
          }
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <Button
              small
              secondary
              style={{ marginRight: '8px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={submitDisabled} small onClick={handleSubmit}>
              Add
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
})
type AddPartProps = {
  open: boolean
  onClose: () => void
  isChecked: boolean[],
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>
}

export default AddPart
