import { Instance, types, SnapshotIn } from 'mobx-state-tree'


export type BOPPartTypeEnum =
  | 'PipeRam'
  | 'ShearRam'
  | 'Annular'
  | 'Connector'
  | 'SpoolPiece'
  | 'WellHead'

export const BOPPartSelectableTypeArray = [
  'PipeRam',
  'ShearRam',
  'Annular',
  'Connector',
  'SpoolPiece',
]

export const BOPPartTypeArray = [
  ...BOPPartSelectableTypeArray,
  'WellHead',
]

export const BOPPartType = types.enumeration(BOPPartTypeArray)

export type BOPPartInstance = Instance<typeof BOPPart>
export type BOPPartAsType = SnapshotIn<typeof BOPPart>


export const BOPPart = types
  .model('BOPPart', {
    partType: BOPPartType,
    name: types.string,
    chokeLine: types.boolean,
    killLine: types.boolean,
    testDate: types.maybeNull(types.Date),
    top: types.maybeNull(types.number), // top of component from datum up if floater, from RKB down otherwise
    mid: types.maybeNull(types.number), // mid of component from datum up if floater, from RKB down otherwise
    })
    .actions((self) => ({
      setType(partType: string) {
        self.partType = partType
      },
      setName(name: string) {
        self.name = name
      },
      setChokeLine(chokeLine: boolean) {
        self.chokeLine = chokeLine
      },
      setKillLine(killLine: boolean) {
        self.killLine = killLine
      },
      setTestDate(testDate: Date | null) {
        self.testDate = testDate
      },
      setTop(height: number | null) {
        self.top = height
      },
      setMid(height: number | null) {
        self.mid = height
      },
    }));

export const createFreshPart = () => BOPPart.create({
  partType: 'WellHead',
  name: '',
  killLine: false,
  chokeLine: false,
});
export default BOPPart
  