import { gql } from '@apollo/client'

export const UserFields = gql`
  fragment UserFields on User {
    id
    firstName
    secondName
    email
    createdAt
    permissions
    scope
    companyId
    rigId
    company {
      id
      name
      imgUrl
    }
    rig {
      id
      name
      type
    }
  }
`
