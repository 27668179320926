export const STAND_LITERALS = ['S', 'D', 'T']
export const LAST_STAND = 1000
export const FEEDBACK_LINK = "mailto:toolbox@endeavortech.com?subject=Radar7 feedback&body=Please include your comments, questions, etc. Your feedback is greatly appreciated."
export const RESET_PASSWORD_LINK = "mailto:toolbox@endeavortech.com?subject=Radar7 support request&body=Please include your company, full name, username and email address."
export const MUIDATAGRIDX = '59745eb2dff57d9475ef6f59bc6d7d3fTz0xMDI2NjksRT0xNzY0Mjc0MDA0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
export const HEALTH_CHECK_INTERVAL = 15000
export const HEALTH_CHECK = false
export const STATUS_INTERVAL = 60000
export const STATUS_CHECK = true
export const siralav = ['Acme', 'InterWell', 'Valaris']
