import Rig from './Rig'
import Company from './Company'

const Summary  = ({
    queryData,
    queryLoading,
}) => {

    return (
        <div className="uiContainer uiContainerShallow">
        <div className="uiContainerTitle">Summary</div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Company: </span>
                <span className="uiContainerLinkText">
                    <Company/>
                </span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Current Rig: </span>
                <span className="uiContainerLinkText">
                    <Rig
                    />
                   
                </span>
            </div>
            {!queryLoading && queryData.getWellSections
            .toSorted((a, b) => {
                const aUpdatedDate = (typeof a.data === 'string' ? JSON.parse(a.data) : a.data).UpdatedAt;
                const bUpdatedDate = (typeof b.data === 'string' ? JSON.parse(b.data) : b.data).UpdatedAt;
                const aComparisonDate = aUpdatedDate ? aUpdatedDate : new Date(a.updatedAt).getTime();
                const bComparisonDate = bUpdatedDate ? bUpdatedDate : new Date(b.updatedAt).getTime();
                return bComparisonDate - aComparisonDate;

            }).slice(0, 1).map(({ id, name, type, status, createdAt, updatedAt, well, data: wellSectionData }) => (
            <div key={id}>
                <div className="uiContainerText">
                    <div style={{ display: 'flex', flexDirection: 'row', height: '22px'  }}>
                        <div>
                            <span className="uiContainerLinkLabel">Current Well: </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '22px'  }}>
                            <div>
                                <span className="dashboardSelect">
                                    {
                                        well.name.length > 50 ? well.name.substring(0, 50) + '...' : well.name
                                    }
                                </span>
                            </div>
                            <div style={{ marginTop: '2px', marginLeft: '-6px' }}>
                                <span className="">
                                    <span className={well.status === 'complete' ? 'dashboardStatusComplete' : 'dashboardStatusInProgress'} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uiContainerText" style={{ marginTop: '3px'}}>
                    <span className="uiContainerLinkLabel" style={{ whiteSpace: 'nowrap'}}>Current Section: </span>
                    <span className="dashboardSelect">{
                        name.length > 50 ? name.substring(0, 50) + '...' : name
                    }</span>
                </div>
            </div>
            ))}
        </div>
    );
}

export default Summary
