import withApollo from 'app/withApollo'
import { createContext, useState, SetStateAction } from 'react'
import { ThemeProvider, withTheme } from '@mui/styles'
import { unstable_createMuiStrictModeTheme } from '@mui/material/styles'
import MainContents from 'app/components/Router'
import 'app/components/kit/fonts/stylesheet.css'
import { BrowserRouter } from 'react-router-dom'

export type User = {
  id: string
  email: string
  password: string
  firstName: string | null
  secondName: string | null
  companyId: string | null
  rigId: string | null
  createdAt: Date
  permissions: string[]
  scope: string[]
  company: {
    id: string
    name: string
    data: any
  }
  rig: {
    id: string
    name: string
    type: string
  }
} | null

export type Company = {
    id: string
    name: string
    data: any
} | null

export type Rig = {
  id: string
  name: string
  type: string
} | null

export const UserContext = createContext<{
  user: User
  setUser: ((arg: SetStateAction<User>) => void) | null
}>({ user: null, setUser: null })

export const CompanyContext = createContext<{
  company: Company
  setCompany: ((arg: SetStateAction<Company>) => void) | null
}>({ company: null, setCompany: null })

export const RigContext = createContext<{
 rig: Rig
 setRig: ((arg: SetStateAction<Rig>) => void) | null
}>({ rig: null, setRig: null })


const App = () => {
  const [user, setUser] = useState<User>(null)
  const [company, setCompany] = useState<Company>(null)
  const [rig, setRig] = useState<Rig>(null)

  const theme = unstable_createMuiStrictModeTheme({
    palette: {
      secondary: { main: '#5C85F5' },
      primary: { main: '#5C85F5' },
      text: { secondary: '#737496', primary: '#fffff' },
    },
  })

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={{ user, setUser }}>
            <CompanyContext.Provider value={{ company, setCompany }}>
            <RigContext.Provider value={{ rig, setRig }}>
              <MainContents />
            </RigContext.Provider>
            </CompanyContext.Provider>
          </UserContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default withTheme(withApollo(App))
