import { useMutation } from '@apollo/client'
import { LOGIN } from 'api'
import { UserContext, User, CompanyContext, RigContext } from 'App'
import { produce } from 'immer'
import { useState, useContext, useEffect, FocusEventHandler, KeyboardEvent } from 'react'
import { useNavigate } from 'react-router'
import Input from './kit/Input'
import { Paper } from './Layout'
import { RESET_PASSWORD_LINK } from '../constants/common'

const Auth = () => {
  const [form, setForm] = useState({ email: '', password: '' })
  const { setUser } = useContext(UserContext)
  const { setCompany } = useContext(CompanyContext)
  const { setRig } = useContext(RigContext)
  const [isErrorMessage, setIsErrorMessage] = useState('');
  const [isErrorInfo, setIsErrorInfo] = useState('');
  const [checkError, setCheckError] = useState(false);
  const router = useNavigate()

  useEffect(() => {
    if (window.localStorage.getItem('session')) {
      router('/')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (checkError) {
      errorWatch()
      setCheckError(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkError])

  const [login, error] = useMutation(LOGIN, {
    onCompleted(data) {
      if (data.login && data.login.session && data.login.user) {
        let companyData = undefined;

        try {
          companyData = JSON.parse(data.login.user.company.imgUrl);
        } catch {}

        const user = data.login.user as User
        if(user && user.company) {user.company.data = companyData}

        setUser && setUser(user)

        const company = {
          "id": data.login.user.companyId,
          "name": data.login.user.company.name,
          "data": companyData
        }

        setCompany && setCompany(company)

        const rig = {
          "id": data.login.user.rig.id,
          "name": data.login.user.rig.name,
          "type": data.login.user.rig.type
        }

        setRig && setRig(rig)

        window.localStorage.setItem('session', data.login.session)

        const context = {
          "company": company,
          "rig": {
            "id": '',
            "name": '',
            "type": '',
          }
        };

        try {
          context.rig.id = data.login.user.rigId
          context.rig.name = data.login.user.rig.name
          context.rig.type = data.login.user.rig.type
        } catch {}

        try {
          window.localStorage.removeItem('context')
        } catch {}

        window.localStorage.setItem('context', JSON.stringify(context));
        router('/dashboard')
      }
      else {
        setIsErrorMessage('Invalid login attempt')
        setCheckError(true)
      }
    },
    onError(e) {
      console.log(e)
      setIsErrorMessage('Invalid login attempt')
      setCheckError(true)
    }
  })

  const handleEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.email = e.target.value
      })
    )
  }
  const handlePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setForm(
      produce(form, (draft) => {
        draft.password = e.target.value
      })
    )
  }

  const resetForm: FocusEventHandler<HTMLInputElement> = (e) => {
    setIsErrorInfo('')
    setIsErrorMessage('')
  }

  const handleSubmit = () => {
    setIsErrorInfo('')
    login({ variables: form })
  }

  function onPressEnter(event: KeyboardEvent<HTMLDivElement>) {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  }

  function errorWatch() {
    if (error) {
      if (error && error.error && error.error?.graphQLErrors.length > 0) {
        if (error.error.message.includes('AuthenticationError: Account temporarily disabled')) {
          // console.log('ERROR: ', error.error?.graphQLErrors[0].message, error.error.message)
          setIsErrorMessage('Maximum failed login attempts exceeded')
        }
      }
    }
  }

  return (
    <Paper>
      <div className="loginWrapper">
        <div className="loginFormContainer">
          <div className="loginFormWrapper">
            <div className="loginWelcome">Welcome, please login</div>
            <div className="formInputFieldLabel">Username</div>
            <Input
              className="formInputField"
              onFocus={resetForm}
              placeholder="Enter username"
              type="text"
              onChange={handleEmail}
              autofocus={true}
            />
            <div className="formInputFieldLabel">Password</div>
            <Input
              className="formInputField"
              onFocus={resetForm}
              placeholder="Enter password"
              type="password"
              onChange={handlePassword}
              onKeyDown={onPressEnter}
            />
            <button
              type="button"
              className={!form.email || !form.password ? 'wideBlueButtonDisabled' : 'wideBlueButton'}
              disabled={!form.email || !form.password}
              onClick={handleSubmit}
            >
              Login
            </button>
            <div className="ancillaryLinksWrapper">
              <div className="horizontalLinkListBlue">
                <a href={RESET_PASSWORD_LINK}>Can't Login?</a>
              </div>
            </div>
            <div className="loginErrorMessaging">
              {isErrorMessage}
            </div>
            <div style={{
              color: 'salmon',
              marginTop: '8px',
            }}>
              {isErrorInfo}
            </div>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default Auth
