import { ReactElement, useContext, MouseEventHandler } from 'react';
import { RouterContext } from '../Router'
import { useMst } from 'app/store'
import { getLabel } from '../../../utils/helpers'
import { AddActionButton } from '../kit/IconButtons'

interface TableHeaderProps {
  firstName: string | null
  lastName: string | null
  role: string
  setLineJustAdded: () => void
}

export function TableHeader(props: TableHeaderProps): ReactElement {
  const {
    store: { TrendSheet, PipeTally },
  } = useMst()
  const { locationState } = useContext(RouterContext)

  const handleAdd: MouseEventHandler<HTMLButtonElement> = () => {
    TrendSheet?.createEmptyRow((props.firstName || '').concat(' ').concat(props.lastName || ''))
    props.setLineJustAdded()
  }

  return (
    <div className="trendSheetRow">
      <div className="trendSheetItem">
        <div
          style={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
          }}
        >
          {(PipeTally?.editingMode === 'RIH' && (props.role === 'Driller' || props.role === 'Support' || props.role === 'Complete')) ? (
              <AddActionButton onClick={handleAdd} />
            ) : null
          }
        </div>
      </div>
      <div className="trendSheetItem">
        Time
      </div>
      <div className="trendSheetItem">
        User
      </div>
      <div className="trendSheetItem">
        Depth<br/>&nbsp;MD ({getLabel('lengthMedium', locationState)})
      </div>
      <div className="trendSheetItem">
        Depth TVD ({getLabel('lengthMedium', locationState)})
      </div>
      <div className="trendSheetItem">
        ROP <br />({getLabel('distanceTime', locationState)})
      </div>
      <div className="trendSheetItem">
        WOB <br />({getLabel('weightLarge', locationState)})
      </div>
      <div className="trendSheetItem">
        RPM
      </div>
      <div className="trendSheetItem">
        Torq. ({getLabel('torque', locationState)})
      </div>
      <div className="trendSheetItem">
        Press. On ({getLabel('pressure', locationState)})
      </div>
      <div className="trendSheetItem">
        Press. Off ({getLabel('pressure', locationState)})
      </div>
      <div className="trendSheetItem">
        Boost press. ({getLabel('pressure', locationState)})
      </div>
      <div className="trendSheetItem">
        SPM
      </div>
      <div className="trendSheetItem">
        Flow In ({getLabel('volumeTime', locationState)})
      </div>
      <div className="trendSheetItem">
        Flow Out (%)
      </div>
      <div className="trendSheetItem" style={{ whiteSpace: 'nowrap' }}>
        Active<br/>({getLabel('volume', locationState)})
      </div>
      <div className="trendSheetItem">
        MW In ({getLabel('weightVolumeSg', locationState)})
      </div>
      <div className="trendSheetItem">
        MW Out ({getLabel('weightVolumeSg', locationState)})
      </div>
      <div className="trendSheetItem">
        ECD<br/>({getLabel('weightVolumeSg', locationState)})
      </div>
      <div className="trendSheetItem">
        Gas<br />(%)
      </div>
      <div className="trendSheetItem" style={{ whiteSpace: 'nowrap' }}>
        Up<br /> ({getLabel('weightLarge', locationState)})
      </div>
      <div className="trendSheetItem">
        Free Rot. ({getLabel('weightLarge', locationState)})
      </div>
      <div className="trendSheetItem">
        Down ({getLabel('weightLarge', locationState)})
      </div>
      <div className="trendSheetItem">
        Azimuth (deg)
      </div>
      <div className="trendSheetItem">
        Inclination (deg)
      </div>
      <div className="trendSheetItem">
        Comment
      </div>
    </div>
  );
}
