import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'App'


const Profile = () => {

    const { user } = useContext(UserContext)
    const [role, setRole] = useState('')

    useEffect(() => {
        const viewer: Array<string> = ['read']
        const complete: Array<string> = ['read', 'full']
        const driller: Array<string> = ['read', 'write']
        const supervisor: Array<string> = ['read', 'owner']
        const support: Array<string> = ['read', 'write', 'owner']

            switch (JSON.stringify(user?.permissions)) {
            case JSON.stringify(support):
                setRole('Support')
                break
            case JSON.stringify(complete):
                setRole('Complete')
                break
            case JSON.stringify(viewer):
                setRole('Viewer')
                break
            case JSON.stringify(driller):
                setRole('Driller')
                break
            case JSON.stringify(supervisor):
                setRole('Supervisor')
                break
            default:
                setRole('Viewer')
                break
        }
    }, [user?.permissions])

    return (
        <div className="uiContainer uiContainerShallow">
        <div className="uiContainerTitle">Profile</div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Name: </span>
                <span className="dashboardSelect">{user?.firstName} {user?.secondName}</span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Email: </span>
                <span className="dashboardSelect">{user?.email}</span>
            </div>
            <div className="uiContainerText">
                <span className="uiContainerLinkLabel">Role: </span>
                <span className="dashboardSelect">{role}</span>
            </div>
        </div>
    )
}

export default Profile
