import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'App'
import { RouterContext } from '../Router'
import { useLazyQuery, gql } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { underscoreToSpace, displayNameFromRigType  } from 'utils/helpers'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import download from '../../../assets/images/icons/icon_export_white.svg'
import warning from '../../../assets/images/icons/icon_warning.svg'

const Badges = () => {
  const { locationState } = useContext(RouterContext)
  const location = useLocation()
  const uri = location.pathname
  let msgRoot : Root | null = null


  const [sim, setSim] = useState('')

  const variables = {
    wellName: underscoreToSpace(uri.split('/')[2]),
    wellSectionName: underscoreToSpace(uri.split('/')[3]),
    company: JSON.parse(localStorage.getItem('context') || '').company.id,
    rig: JSON.parse(localStorage.getItem('context') || '').rig.id,
  }

  const { user } = useContext(UserContext)
  const [role, setRole] = useState('')

  const [getSim] = useLazyQuery(
    gql`
      query getSimXml(
        $wellSectionName: String!
        $wellName: String!
        $company: String!
        $rig: String!
      ) {
        getSimXml(
          wellSectionName: $wellSectionName
          wellName: $wellName
          company: $company
          rig: $rig
        ) {
          simFile
        }
      }
  `,
  {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data.getSimXml.simFile) {
          setSim(data.getSimXml.simFile)
        }
      },
      onError(err) {
          console.error(err)
      }
  }
)

function closeModal() {
  if (msgRoot) {
    msgRoot.unmount()
  }
}

const giveFeedback = {
  closeOnOverlayClick: true,
  render: (message: string, onCancel: any, result: string, bgColor: string, warn: boolean) => {
    let bg = bgColor
    let msgColor = '#FFF'
    if (result === 'success' && warn) {
      bg = 'darkorange'
      msgColor = '#000000'
    }
      return (
          <div id="feedbackMessaging" className="centeredDiv" style={{ zIndex: 100, backgroundColor: `${bg}` }}>
              <div style={{ width: '860px', margin: '20px', padding: '20px', marginBottom: '15px' }}>
                  <div style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                      <img alt="messages" src={result === 'success' ? download : warning} style={{ marginTop: '10px', width: '75px', height: '75px', marginRight: '10px' }} />
                  </div>
                  <div style={{ whiteSpace: 'pre-line', lineHeight: '20px', color: `${msgColor}`, fontWeight: 500, fontSize: '12pt', marginBottom: '30px' }}>
                      {message}
                  </div>
                  <button style={{ backgroundColor: '#0096FF', color: '#FFF' }} onClick={closeModal} className="popupAlertButton"> OK </button>
              </div>
          </div>
      )
  }
}

useEffect(() => {
  downloadSim()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [sim])

useEffect(() => {
  if (user && user?.permissions.length > 0) {
      const viewer = ['read']
      const complete: Array<string> = ['read', 'full']
      const driller = ['read', 'write']
      const supervisor = ['read', 'owner']
      const support = ['read', 'write', 'owner']

      switch (JSON.stringify(user?.permissions)) {
          case JSON.stringify(support):
              setRole('Support')
              break
          case JSON.stringify(complete):
              setRole('Complete')
              break
          case JSON.stringify(viewer):
              setRole('Viewer')
              break
          case JSON.stringify(driller):
              setRole('Driller')
              break
          case JSON.stringify(supervisor):
              setRole('Supervisor')
              break
          default:
              setRole('Viewer')
              break
      }
  }
}, [user])

function downloadSim() {
  if (sim && sim.length > 50) {
    const simFile = sim.split('¤')
    const result = simFile[0]
    const messages = simFile[1]
    // const dts = simFile[2] // exists to stop caching of prior sim file

    let validFile = false
    let warn = false

    if (simFile.length > 1) {
      if (
        result.includes('</WebModule>') &&
        messages.includes('Successfully exported')
      ) {
        validFile = true
      }
    }

    if (validFile) {
      const file = new Blob([sim.split('¤')[0]], {type: 'text/xml'}); // text/plain
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = `SIM-Launcher-${Date.now()}.dsts`;
      document.body.appendChild(element);
      element.click();
    }

    let feedback = ''
    if (validFile) {
      feedback = 'RESULT:\nSim file was generated and has been downloaded.\n\nNOTE:\nSim files are based on saved data.\n\n'
    } else {
      feedback = 'RESULT:\nSim file was not generated.\n\n'
    }

    if (
      result === undefined ||
      messages.includes('Failed to export')
    ) {
      feedback += 'Sim file Failed to export'
    }

    const warnings = messages.split('Warning: ')

    if (warnings.length > 1) {
      console.log('WARNINGS:', warnings)
      warn = true
      feedback += 'WARNINGS:\n'
      for (let i = 1; i < (warnings.length > 10 ? 10 : warnings.length); i++) {
        feedback += '● ' + warnings[i] + '. '
      }
      if (warnings.length > 10) {
        feedback += '... plus other warnings'
      }
    }

    if (feedback && feedback.length > 0) {
      // feedback = feedback.replace(/(?:\r\n|\r|\n)/g, '<br>')
      feedback = feedback.replaceAll('\n\t.', '\n\t')
      const feedbackMessages = React.createElement('div', { id: 'badges' }, giveFeedback.render(
        feedback, () => {},
        validFile ? 'success' : 'warning',
        validFile ? 'forestgreen' : 'firebrick',
        warn ? true : false
      ))
      if(!msgRoot) {
        const elem = document.getElementById('feedbackMessaging') as HTMLDivElement
        msgRoot = createRoot(elem)
      }
      msgRoot.render(feedbackMessages)
    }
  } else {
    if (sim) {
      console.log('Error generating sim file')
    }
  }
}

  return (
    <div className="textGeneric wellSectionHeaderStatus">
      <span className="uiContainerStatusMessage">
        {displayNameFromRigType(locationState?.rigType, false)}
      </span>
      <span className="uiContainerStatusWarning" style={{ marginLeft: '8px' }}>
        {locationState?.units}
      </span>
      <span className="uiContainerStatusNotification" style={{ marginLeft: '8px' }}>
        {locationState?.wellSectionType === 'CSG' ? 'CASING' : 'OPEN HOLE'}
      </span>
      {role === 'Support' ? (
      <span className="uiContainerStatusDownload">
        <button
          style={{ cursor: 'pointer', color: 'rgb(240, 255, 255, 0.8)', fontWeight: 700, backgroundColor: 'transparent', border: 'none', borderRadius: '8px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: '16px', paddingRight: '16px', marginLeft: '0px' }}
          onClick={() => {
            getSim()
          }}
        >
          SIM
        </button>
      </span> ) : null}
      <div id="feedbackMessaging" className="popupAlert" />
    </div>
  );
}

export default Badges