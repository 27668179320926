import { useContext, useEffect, useState } from 'react'
import { UserContext, CompanyContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import iconCaretDown from '../../../assets/images/icons/icon_caretDown.svg'

const Company = () => {
    const { user } = useContext(UserContext)
    const { company, setCompany } = useContext(CompanyContext)
    const [scope, setScope] = useState('')

    const { data, loading } = useQuery(
        gql`
        query company {
            company {
                id
                name
                imgUrl
            }
        }
        `,
        {
            fetchPolicy: "no-cache"
        }
    )

    useEffect(() => {
        const operator = ['rig']
        const shorebased = ['rig', 'company']
        const support = ['rig', 'company', 'global']

            switch (JSON.stringify(user?.scope)) {
            case JSON.stringify(operator):
                setScope('operator')
                break
            case JSON.stringify(shorebased):
                setScope('shorebased')
                break
            case JSON.stringify(support):
                setScope('support')
                break
            default:
                setScope('operator')
                break
        }
    }, [user?.scope])


    function handleChange(event) {
        const thisCompany = data.company.find((c) => c.id === event.target.value)
        const context = JSON.parse(window.localStorage.getItem('context'))
        context.company.id = thisCompany.id
        context.company.name = thisCompany.name
        try{
            context.company.data = JSON.parse(thisCompany.imgUrl)
        } catch {
            context.company.data = undefined;
        }
        
        window.localStorage.setItem('context', JSON.stringify(context))

        setCompany && setCompany(context.company)
    }

    return (
        <>
            {!loading && scope === 'support' ? (
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <img alt="Company" src={iconCaretDown} />
                    </div>
                    <div style={{ marginLeft: '-8px' }}>
                        <select
                            id="currentCompany"
                            onChange={handleChange}
                            value={
                                company?.id
                            }
                            title="Select a Company"
                            className="dashboardSelect"
                            style={{ height: '30px !important', backgroundColor: 'transparent' }}
                        >
                        {data?.company.map((comp) => (
                            <option key={comp.id} value={comp.id} style={{ color: '#5c85f5', backgroundColor: '#34354f'}}>
                                {comp.name}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className="dashboardSelect">
                    {company?.name}
                </div>
            )}
        </>
    );
}

export default Company
