import { useQuery, gql, useMutation } from '@apollo/client'
import { useState, useEffect, useCallback } from 'react'
import { useMst } from 'app/store'
import Dialog from '../kit/Dialog'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Input from '../kit/Input'
import { getLabel } from 'utils/helpers'
import { Button, ImportButton } from '../kit/SmallWidthButton'
import './Tubulars.css'
import { makeStyles } from '@mui/styles'


const Head = ({pipeType}) =>
<thead>
  {
    pipeType === 'SP' ? (
      <tr>
        <th style={{ width: 275 }}>Description</th>
        <th style={{ width: 110 }}>Type</th>
        <th style={{ width: 110 }}>Serial</th>
        <th style={{ width: 70 }}>In use</th>
      </tr>
    ) 
    :             
    (
      <tr>
        <th style={{ width: 275 }}>Description</th>
        <th style={{ width: 110 }}>Connection</th>
        <th style={{ width: 75 }}>OD&nbsp;({getLabel('lengthSmall')})</th>
        <th style={{ width: 70 }}>ID&nbsp;({getLabel('lengthSmall')})</th>
        <th style={{ width: 70 }}>MUT Min ({getLabel('torqueSmall')})</th>
        <th style={{ width: 70 }}>MUT Max ({getLabel('torqueSmall')})</th>
        <th style={{ width: 75 }}>Adj. Weight ({getLabel('weightDistance')})</th>
        <th style={{ width: 76 }}>Adj. Disp. ({getLabel('volumeDistance')})</th>
        <th style={{ width: 76 }}>Adj. Cap. ({getLabel('volumeDistance')})</th>
        <th style={{ width: 70 }}>Range</th>
        <th style={{ width: 100 }}>Tensile<br/>strength ({getLabel('weight')})</th>
        <th style={{ width: 100 }}>Torsional<br/>strength ({getLabel('torqueSmall')})</th>
      </tr>
    )
  }
</thead>

const formState = {
  name: '',
  type: '',
  ttype: '',
  serial: '',
  connection: '',
  OD: '',
  ID: '',
  weight: '',
  disp: '',
  cap: '',
  range: '',
  mutMin: '',
  mutMax: '',
  tenStrength: '',
  torStrength: '',
  standard: '',
  rigId: '',
}

const CreatePipe = ({ refetch, refetchSP, open, onClose: oc, pipeType }) => {
  const {
    store: { getTubulars },
  } = useMst()
  formState.type = pipeType;
  const onClose = useCallback(() => {
    for(const key in formState) { formState[key] = '';}
    oc()
  }, [oc])

  const [createPipe] = useMutation(
    gql`
      mutation createPipe(
        $pipeType: String!
        $name: String!
        $connection: String!
        $OD: Float!
        $ID: Float!
        $weight: Float!
        $disp: Float!
        $cap: Float!
        $range: Int!
        $mutMin: Int!
        $mutMax: Int!
        $tenStrength: Int!
        $torStrength: Int!
        $standard: Boolean!
        $rigId: String!
      ) {
        createPipe(
          # data: {
            pipeType: $pipeType
            name: $name
            connection: $connection
            OD: $OD
            ID: $ID
            weight: $weight
            disp: $disp
            cap: $cap
            range: $range
            mutMin: $mutMin
            mutMax: $mutMax
            tenStrength: $tenStrength
            torStrength: $torStrength
            standard: $standard
            rigId: $rigId
          # }
        ) {
          id
        }
      }
    `,
    {
      onCompleted() {
        refetch()
        getTubulars({ rigId: JSON.parse(localStorage.getItem('context') || '').rig.id })
        onClose()
      },
    }
  )

  const [createSP] = useMutation(
    gql`
      mutation createSwivelPack(
        $name: String!
        $type: String!
        $serial: String!
        $rigId: String!
      ) {
        createSwivelPack(
            name: $name
            type: $type
            serial: $serial
            rigId: $rigId
        ) {
          id
        }
      }
    `,
    {
      onCompleted() {
        refetchSP()
        onClose()
      },
    }
  )

  const handleInputs = (key) =>
    function (e) {
      formState[key] = e.target.value;
    }


  const handleSubmit = () => {
    if(isDisabled()) return;
    if(formState.type === 'SP') {
      createSP({
        variables: {
          name: formState.name,
          type: formState.ttype,
          serial: formState.serial,
          rigId: JSON.parse(localStorage.getItem('context')).rig.id
        },
      })  
    } else {
      createPipe({
        variables: {
          pipeType: formState.type,
          name: formState.name,
          connection: formState.connection,
          OD: Number(formState.OD),
          ID: Number(formState.ID),
          weight: Number(formState.weight),
          disp: Number(formState.disp),
          cap: Number(formState.cap),
          range: Number(Number(formState.range).toFixed(0)),
          mutMin: Number(formState.mutMin),
          mutMax: Number(formState.mutMax),
          tenStrength: Number(formState.tenStrength),
          torStrength: Number(formState.torStrength),
          standard: false,
          rigId: JSON.parse(localStorage.getItem('context')).rig.id
        },
      })  
    }
  }

  const isDisabled = () =>
    formState.type === 'SP' ? ( 
      !formState.name ||
      !formState.ttype ||
      !formState.serial
    )
    : (
    !formState.name ||
    !formState.connection ||
    !formState.OD ||
    !formState.ID ||
    !formState.weight ||
    !formState.disp ||
    !formState.cap ||
    !formState.range ||
    !formState.mutMin ||
    !formState.mutMax ||
    !formState.tenStrength ||
    !formState.torStrength
    )

  return (
    <Dialog open={open} onClose={onClose} style={{ maxWidth: 464, padding: 0 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '12px 0px',
        }}
      >
        <h3>Create tubular</h3>
        <div style={{
          textAlign: 'center',
          fontSize: '13px',
          color: 'dimgray',
          paddingBottom: '10px',
          backgroundColor: '#393a4c',
          fontWeight: 500,
        }}>
          <div style={{ color: '#fff', padding: '20px' }}>
            You are about to create a new tubular.
            Make sure it is not already existing and that the data is correct.
            Only a Support Administrator can modify and remove tubulars from the library.
          </div>
          <span style={{ fontWeight: 800, color: 'red' }}>Imperial Measurements Only</span>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', margin: '0 12px' }}
        >
          {pipeType === 'SP' ? 
          (
            <>
              <Input
                title="Description"
                id="create-lib-pipe-name"
                type="text"
                defaultValue={formState.name}
                onChange={handleInputs('name')}
                className="inputModalField"
                labelClassName="placeholder"
                wrapperClassName="field"
              />
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title="Type"
                  id="create-lib-pipe-ttype"
                  type="text"
                  defaultValue={formState.ttype}
                  onChange={handleInputs('ttype')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title="Serial"
                  id="create-lib-pipe-serial"
                  type="text"
                  defaultValue={formState.serial}
                  onChange={handleInputs('serial')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
            </>
          ) 
          : 
          (
            <>
              <Input
                title="Description"
                id="create-lib-pipe-name"
                type="text"
                defaultValue={formState.name}
                onChange={handleInputs('name')}
                className="inputModalField"
                labelClassName="placeholder"
                wrapperClassName="field"
              />
              <Input
                title="Connection"
                id="create-lib-pipe-connection"
                type="text"
                defaultValue={formState.connection}
                onChange={handleInputs('connection')}
                className="inputModalField"
                labelClassName="placeholder"
                wrapperClassName="field"
              />
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title={"OD (" + getLabel('lengthSmall') + ")"}
                  id="create-lib-pipe-od"
                  type="number"
                  step="0.1"
                  defaultValue={formState.OD}
                  onChange={handleInputs('OD')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title={"ID (" + getLabel('lengthSmall') + ")"}
                  id="create-lib-pipe-id"
                  type="number"
                  step="0.1"
                  defaultValue={formState.ID}
                  onChange={handleInputs('ID')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title={"Adj. Weight (" + getLabel('weightDistance') + ")"}
                  id="create-lib-pipe-weight"
                  type="number"
                  step="0.1"
                  defaultValue={formState.weight}
                  onChange={handleInputs('weight')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title={"Adj. Disp. (" + getLabel('volumeDistance') + ")"}
                  id="create-lib-pipe-disp"
                  type="number"
                  step="0.1"
                  defaultValue={formState.disp}
                  onChange={handleInputs('disp')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title={"Adj. Cap. (" + getLabel('volumeDistance') + ")"}
                  id="create-lib-pipe-cap"
                  type="number"
                  step="0.1"
                  defaultValue={formState.cap}
                  onChange={handleInputs('cap')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title="Range"
                  id="create-lib-pipe-range"
                  type="number"
                  step="1"
                  defaultValue={formState.range}
                  onChange={handleInputs('range')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title={"MUT Min (" + getLabel('torqueSmall') + ")"}
                  id="create-lib-pipe-mutmin"
                  type="number"
                  step="1"
                  defaultValue={formState.mutMin}
                  onChange={handleInputs('mutMin')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title={"MUT Max (" + getLabel('torqueSmall') + ")"}
                  id="create-lib-pipe-mut_max"
                  type="number"
                  step="1"
                  defaultValue={formState.mutMax}
                  onChange={handleInputs('mutMax')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
                <Input
                  title={"Tens. Strength (" + getLabel('weight') + ")"}
                  id="create-lib-pipe-ten-str"
                  type="number"
                  step="0.1"
                  defaultValue={formState.tenStrength}
                  onChange={handleInputs('tenStrength')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
                <Input
                  title={"Tors. Strength (" + getLabel('torqueSmall') + ")"}
                  id="create-lib-pipe-tor-str"
                  type="number"
                  step="0.1"
                  defaultValue={formState.torStrength}
                  onChange={handleInputs('torStrength')}
                  className="inputModalField"
                  labelClassName="placeholder"
                  wrapperClassName="field"
                />
              </div>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '7px', columnGap: '8px' }}>
            <Button small secondary onClick={onClose}>
              Cancel
            </Button>
            <Button small onClick={handleSubmit}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles({
  StyledTab: {
    textTransform: 'none',
    color: '#737496 !important',
    '&.Mui-selected > span': { color: '#fff !important' },
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: 0,
    marginRight: 32,
  },
})

const PipesLibrary = () => {
  const [open, setOpen] = useState(false)
  const [pipeType, setPipeType] = useState('DP')
  const { data, loading, refetch } = useQuery(
    gql`
    query getPipes($type: String!, $rigId: String!) {
      getPipes(
            type: $type,
            rigId: $rigId
      ) {
        id
        type
        name
        connection
        OD
        ID
        weight
        disp
        cap
        range
        mutMin
        mutMax
        tenStrength
        torStrength
        standard
        rigId
      }
    }
    `,
    {
      variables: {
        type: pipeType,
        rigId: localStorage.getItem('context') ? JSON.parse(localStorage.getItem('context') || '').rig.id : ''
      },
    }
  )

  const { data: dataSP, loading: loadingSP, refetch: refetchSP } = useQuery(
    gql`
    query getSwivelPacks($rigId: String!) {
      getSwivelPacks(
            rigId: $rigId
      ) {
        id
        type
        name
        serial
        inUse
        rigId
      }
    }
    `,
    {
      variables: {
        rigId: localStorage.getItem('context') ? JSON.parse(localStorage.getItem('context') || '').rig.id : ''
      },
      fetchPolicy: 'network-only',
    }
  )

  const [setCurrentSwivelPack] = useMutation(
    gql`
    mutation setCurrentSwivelPack($rigId: String!, $swivelPackId: String!) {
      setCurrentSwivelPack(rigId: $rigId, swivelPackId: $swivelPackId)
    }
    `, 
    {
       onCompleted: () => {refetchSP();}
    }
  );


  useEffect(() => {
    if(pipeType === 'SP') refetchSP()
    else refetch({ type: pipeType })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeType])

  const classes = useStyles();

  return (
    <>
      <CreatePipe
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        refetch={refetch}
        refetchSP={refetchSP}
        pipeType={pipeType}
      />
      <div
        id="tubularsTabs"
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Tabs
          value={pipeType}
          onChange={(_, value) => {
            setPipeType(value)
          }}
        >
          <Tab className={classes.StyledTab} disableRipple value="DP" label="Drill Pipes" />
          <Tab className={classes.StyledTab} disableRipple value="HWDP" label="Heavy Weight Drill Pipes" />
          <Tab className={classes.StyledTab} disableRipple value="DC" label="Drill Collars" />
          <Tab className={classes.StyledTab} disableRipple value="LS" label="Landing Strings" />
          <Tab className={classes.StyledTab} disableRipple value="CSG" label="Casing Strings" />
          <Tab className={classes.StyledTab} disableRipple value="LIN" label="Liner Strings" />
          <Tab className={classes.StyledTab} disableRipple value="SP" label="Swivel Packs" />
        </Tabs>
        <ImportButton
          title="Create tubular"
          onClick={() => setOpen(true)}
        />
      </div>
      <table className="table pipe">
        <Head pipeType={pipeType} />
        <tbody>
          {
          pipeType === 'SP' ?
            !loadingSP &&
            dataSP?.getSwivelPacks.map((sp) => (
              <tr key={sp.id}>
                <td className="hl">
                  {sp.name}
                </td>
                <td className="hl">{sp.type}</td>
                <td className="hl">
                  {sp.serial}
                </td>
                <td className="hl">
                  <input type="radio" value={sp.id} name="inUse" id={sp.id}
                    defaultChecked={sp.inUse}
                    onClick={((e) => {
                       if(e.target.defaultChecked) {
                        e.target.checked = false;
                        setCurrentSwivelPack({variables: { rigId: sp.rigId, swivelPackId: ""}})
                       } else {
                        setCurrentSwivelPack({variables: { rigId: sp.rigId, swivelPackId: e.target.value}})
                       }
                    })}
                  />
                </td>
              </tr>
            ))
          : !loading &&
            data?.getPipes.map((pipe) => (
              <tr key={pipe.id}>
                <td className="hl">
                  {pipe.name}
                </td>
                <td>{pipe.connection}</td>
                <td className="hl">
                  {pipe.OD.toFixed(3)}
                </td>
                <td className="hl">
                  {pipe.ID.toFixed(3)}
                </td>
                <td className="hl">
                  {pipe.mutMin.toString()}
                </td>
                <td className="hl">
                  {pipe.mutMax.toString()}
                </td>
                <td className="hl">
                  {pipe.weight.toFixed(3)}
                </td>
                <td className="hl">
                  {pipe.disp.toFixed(4)}
                </td>
                <td className="hl">
                  {pipe.cap.toFixed(4)}
                </td>
                <td style={{ textAlign: 'center' }} className="hl">
                  {pipe.range.toString()}
                </td>
                <td>{pipe.tenStrength.toString()}</td>
                <td>{pipe.torStrength.toString()}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default PipesLibrary
