import { useContext, useReducer } from 'react'
import { Paper } from '../Layout'
import { RouterContext } from '../Router'
import { CheckMarkButton } from '../kit/RadioButton'
import { getLabel, unitConversion } from '../../../utils/helpers'
import { BOPPartSelectableTypeArray } from '../../models/BOP/BOPPart'
import BOPTowerPart from './Part'
import { SectionTabs } from '../WellSection/SectionTabs'
import { observer } from 'mobx-react-lite'
import { useMst } from 'app/store'
import { ReactComponent as AddIcon } from 'app/components/kit/icons/PlusCircleBlue.svg'
import trashIcon from '../../../assets/images/icons/icon_trash.svg'
import upArrowIcon from '../../../assets/images/icons/icon_triangle_arrow_up.png'
import downArrowIcon from '../../../assets/images/icons/icon_triangle_arrow_down.png'
import { ReactComponent as PipeRam } from 'app/components/kit/images/bopparts/icon_dot_bop_pipe_ram.svg'
import { ReactComponent as ShearRam } from 'app/components/kit/images/bopparts/icon_dot_bop_shear_ram.svg'
import { ReactComponent as Annular } from 'app/components/kit/images/bopparts/icon_dot_bop_annular.svg'
import { ReactComponent as Connector } from 'app/components/kit/images/bopparts/icon_dot_bop_connector.svg'
import { ReactComponent as SpoolPiece } from 'app/components/kit/images/bopparts/icon_dot_bop_spool.svg'
import { ReactComponent as WellHeadFloater } from 'app/components/kit/images/bopparts/wellheadconnector.svg'
import { ReactComponent as WellHead } from 'app/components/kit/images/bopparts/wellheadconnector-without-line.svg'
import './index.css'

const hideLowered = true;

const BOP = observer(() => {
  const {
    store: { BOP, WellM, TrendSheet, PipeTally, CasingTally, LinerTally, BHA }
  } = useMst()

  const { locationState } = useContext(RouterContext)

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  let defaultBitDepth = null;
  if(TrendSheet && PipeTally && PipeTally.editingMode === 'RIH' && TrendSheet.rows.length > 0) {
    const recordedBitDepth = TrendSheet.rows[TrendSheet.rows.length-1].depth;
    if(recordedBitDepth) defaultBitDepth = recordedBitDepth;
  }

  const isFloater = () => {
    return locationState?.rigType === 'floater';
  }

  const convertLengthForOutput = (val, decimals) => {
      if(val === null || val === undefined) return '';
      const res = unitConversion('lengthMedium', locationState?.units, 'out', val, 15);
      return decimals ? res.toFixed(2) : res;
  }

  const convertLengthForInput = (val, isNullable) => {
      if(val === null || val === undefined) return isNullable ? val : 0;
      const num = parseFloat(val);
      if(num === null || Number.isNaN(num)) return isNullable ? null : 0;
      return unitConversion('lengthMedium', locationState?.units, 'in', num, 15)
  }

  const getTideNumeric = () => {
    if(BOP && BOP.tide) return BOP.tide;
    return 0;
  }

  const createDerived = (rkbToDatum) => {
    const res = {RKBToDatumTop: [], RKBToDatumMid: []};
    BOP?.bop.parts.forEach((part) => {
      res.RKBToDatumTop.push(part.top === null || part.top === undefined ? null : (isFloater() ? rkbToDatum - part.top + getTideNumeric(): part.top));
      res.RKBToDatumMid.push(part.mid === null || part.mid === undefined ? null : (isFloater() ? rkbToDatum - part.mid + getTideNumeric(): part.mid));
    });
    return res;
  }

  const pipeDelta = (pipeShare, targetDepth, fullDepth, isUp) => {
    if(targetDepth === null || fullDepth === null) return null;

    const toTarget = fullDepth - targetDepth;

    let l1 = 0;
    let totalLength = 0;


    BHA?.tally(0).map((part, i) => {
      return totalLength += parseFloat(part.length)
    })

    // special case: top of BHA higher than target to begin with
    if(totalLength - toTarget > 0) {
        if(isUp) return null; // cannot move up to target if target is lower then top of BHA
        if(!pipeShare) return (totalLength - toTarget); // need to move down to hang top of BHA by this much
    }

    const processTally = (tally) => {
      if(tally && tally.strings) {
        for(let i = 0; i < tally.strings.length; i++) {
          for(let j = 0; j < tally.strings[i].list.data.length; j++) {
            const l = tally.strings[i].list.data[j].length;
             totalLength += l;
             const delta = totalLength - pipeShare * l - toTarget;
             if(delta >= 0) {
                return isUp ? (1 - pipeShare) * l + pipeShare * l1 - delta : delta;
             }
             l1 = l;
          }  
        }  
      }
      return null; // have not found anything yet
    }

    let processed = processTally(LinerTally);
    if(processed !== null) return processed;
    processed = processTally(CasingTally);
    if(processed !== null) return processed;
    return processTally(PipeTally); // will be null if nothing was processed
  }

  const createComputed = (currentBitDepth, derived) => {
    const res = { hangOff: [], midPipe : [], hangOffDown: [], midPipeDown: []};
    for(let i = 0; i < BOP.bop.parts.length; i++) {
      if(currentBitDepth) {
        const mHangOff = pipeDelta(0, derived.RKBToDatumTop[i], currentBitDepth, true)
        res.hangOff.push(mHangOff === null ? null : pipeDelta(0,0,currentBitDepth-mHangOff,false));
        const mMidPipe = pipeDelta(0.5, derived.RKBToDatumMid[i], currentBitDepth, true)
        res.midPipe.push(mMidPipe === null ? null : pipeDelta(0,0,currentBitDepth-mMidPipe,false));  
        const mHangOffDown = pipeDelta(0, derived.RKBToDatumTop[i], currentBitDepth, false)
        res.hangOffDown.push(mHangOffDown === null ? null : pipeDelta(0,0,currentBitDepth+mHangOffDown,false));
        const mMidPipeDown = pipeDelta(0.5, derived.RKBToDatumMid[i], currentBitDepth, false)
        res.midPipeDown.push(mMidPipeDown === null ? null : pipeDelta(0,0,currentBitDepth+mMidPipeDown,false));  
      } else {
        res.hangOff.push(null);
        res.midPipe.push(null);  
        res.hangOffDown.push(null);
        res.midPipeDown.push(null);  
      }
    }
    return res;
  }

  const PartRow = observer(({part, i, derivedData, computedData}) => {

    const CheckMarkButtonObservable = observer((props) => CheckMarkButton(props))

    return (
        <tr>
          <td>
          { i < 0 ?
            (
              <button
                type="button"
                onClick={() => {
                  if(BOP.newPart.partType !== 'WellHead') {
                    BOP.addPart();
                    forceUpdate();  
                  }
                }}
              >
                <AddIcon className={part.partType !== 'WellHead' ? 'enabled' : 'disabled'}/>
              </button>

            )
            :
            ((part.partType !== 'WellHead') ?
              (
                <button
                  type="button"
                  onClick={() => {
                    BOP.removePart(i);
                    forceUpdate();
                  }}
                >
                  <img alt="delete part" src={trashIcon} />
                </button>

              ) : null)
          }
          </td>
          <td>
            <div className={'verticalStack'}>
              <div>
                {
                  part.partType !== 'WellHead' && i > 0 ?
                  (
                    <button
                      type="button"
                      onClick={() => {
                        BOP.movePart(i, i-1);
                        forceUpdate();
                      }}
                    >
                      <img alt="move up" src={upArrowIcon} />
                    </button>
                  ) : null
                }
              </div>
              <div>
                { (part.partType !== 'WellHead' && i >= 0 && i < BOP.partLength() - 2) ?
                  (
                    <button
                    type="button"
                    onClick={() => {
                      BOP.movePart(i, i+1);
                      forceUpdate();
                    }}
                    >
                      <img alt="move down" src={downArrowIcon} />
                    </button>
                  ) : null
                }
              </div>
            </div>
          </td>
          <td>
            <div className={'verticalStack'}>              
                { part.partType === 'WellHead' && i >= 0 ?
                  ( <div className='partType'>{part.partType}</div> )
                  :
                  (
                    <div className={'selectContainer'}>
                      <select
                      id={"wellhead" + i}
                    value={i < 0 && part.partType === 'WellHead' ? '' : part.partType}
                    onChange={(e)=> { 
                      if(e.target.value) {
                        part.setType(e.target.value);
                      }
                    }}
                  >
                    {i < 0 && part.partType === 'WellHead' ? (<option key={'empty'} disabled hidden value=''/>) : null}
                    {BOPPartSelectableTypeArray.map((key) => {
                      return (
                        <option id={"boppartselect" + i} key={key} value={key}>
                          {key}
                        </option>
                      )
                    })}                            
                  </select>
                  </div>
                  )
                }
              <div>
                <input
                  id={"part-weight-input" + i}
                  type="text"
                  value={part.name}
                  onChange={(e)=> { 
                    part.setName(e.target.value);
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            {part.partType === 'SpoolPiece' ? (
                  <CheckMarkButtonObservable
                    defaultChecked={part.killLine}
                    onClick={(e)=> { 
                      e.target.checked = !part.killLine;
                      part.setKillLine(e.target.checked);
                    }}
                  />
            ) : null}
          </td>
          <td>
            {part.partType === 'SpoolPiece' ? (
                  <CheckMarkButtonObservable
                    defaultChecked={part.chokeLine}
                    onClick={(e)=> { 
                      e.target.checked = !part.chokeLine;
                      part.setChokeLine(e.target.checked);
                    }}
                  />
            ) : null}
          </td>
          <td>
            <input
              type="date"
              value={part.testDate ? part.testDate.toISOString().split("T")[0] : ''}
              onChange={(e)=> { 
                part.setTestDate(e.target.valueAsDate);
              }}
            />
          </td>
          <td>
            <div className="verticalStack">
              <input
                id={"setTop" + i}
                type="number"
                value={convertLengthForOutput(part.top, false)}
                onChange={(e)=> { 
                  const val = convertLengthForInput(e.target.value, true);
                  part.setTop(val);
                }}
                step="any"
              />
              <input
                  id={"setMid" + i}
                  type="number"
                  value={convertLengthForOutput(part.mid, false)}
                  onChange={(e)=> { 
                    const val = convertLengthForInput(e.target.value, true);
                    part.setMid(val);
                  }}
                  step="any"
              />
              </div>
          </td>
          {isFloater() ? (
          <td>
          <div className="verticalStack">
            <div>
              {i < 0 ? null : convertLengthForOutput(derivedData.RKBToDatumTop[i], true)}
            </div>
            <div>
              {i < 0 ? null : convertLengthForOutput(derivedData.RKBToDatumMid[i], true)}
            </div>
          </div>
          </td>
          ) : null}
          <td>
            <div className="verticalStack">
              <div>
                {i < 0 ? null : convertLengthForOutput(computedData.hangOff[i], true)}
              </div>
              <div>
                {i < 0 ? null : convertLengthForOutput(computedData.midPipe[i], true)}              
              </div>
            </div>
          </td>
          {hideLowered ? null : (
            <td>
            <div className="verticalStack">
              <div>
                {i < 0 ? null : convertLengthForOutput(computedData.hangOffDown[i], true)}
              </div>
              <div>
                {i < 0 ? null : convertLengthForOutput(computedData.midPipeDown[i], true)}
              </div>
            </div>
          </td>
          )}
        </tr>
    )

  })

  const currentOrDefaultBitDepth = () => {
     const res = BOP && BOP.bitDepth !== undefined ? BOP.bitDepth : defaultBitDepth;
     return res ? res : null;
  }

  const BOPControls = observer(() => {
    return (
        <div className="bopControls">
          <label
            htmlFor="bit-depth-input"
            style={{ pointerEvents: 'none' }}
          >
            Bit Depth:&nbsp;
          </label>
          <input
            id="bit-depth-input"
            type="number"
            defaultValue={convertLengthForOutput(currentOrDefaultBitDepth(), true)}
            onBlur={(e) => BOP.setBitDepth(convertLengthForInput(e.target.value, true))}
          />
          <button
            type="button"      
            onClick={() => {
              document.getElementById('bit-depth-input').value = convertLengthForOutput(defaultBitDepth, true);
              BOP.setBitDepth(defaultBitDepth);
            }}
          >
            Reset
          </button>
          {
            isFloater() ? (
              <>
                  <label
                  htmlFor="tide-input"
                  style={{ pointerEvents: 'none' }}
                >
                  Tide:&nbsp;
                </label>
                <input
                  id="tide-input"
                  type="number"
                  value={convertLengthForOutput(BOP?.tide, false)}
                  onChange={(e) => BOP.setTide(convertLengthForInput(e.target.value, true))}
                />  
              </>
            ) : null
          }
        </div>
    )  
  })

  const BOPTable = observer(() => {
    if(!BOP) return null;

    const derivedData = createDerived(WellM.rkbToDatum);

    const computedData = createComputed(currentOrDefaultBitDepth(), derivedData);
    
    const { locationState } = useContext(RouterContext)

    const BOPparts = BOP?.bop.parts;

    let lastKillLineIndex = undefined;
    let lastChokeLineIndex = undefined;

    const image  = {
      PipeRam: PipeRam,
      ShearRam: ShearRam,
      Annular: Annular,
      Connector: Connector,
      SpoolPiece: SpoolPiece,
      WellHead: locationState.rigType === 'floater' ? WellHeadFloater : WellHead,
    }


    for(let i = 0; i < BOPparts.length; i++) {
      if(BOPparts[i].killLine) lastKillLineIndex = i;
      if(BOPparts[i].chokeLine) lastChokeLineIndex = i;
    }
    
    return (
      <div style={{ display: 'flex', gap: '1rem'}}>
        <div style={{display: 'flex', width: '20%', justifyContent: 'center', minWidth: '23.75rem', marginTop: '20px'}}>
          <div style={{width: '100%'}}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
              <div>{lastKillLineIndex !== undefined ? (<p style={{fontSize: '12px'}}>Kill Line</p>) : null}</div>
              <div>{lastChokeLineIndex !== undefined ? (<p style={{textAlign: 'right', fontSize: '12px'}}>Choke Line</p>) : null}</div>
            </div>
          {
            BOPparts.map(({ partType, chokeLine, killLine }, i) => {
              
            return (
              <BOPTowerPart
                key={i}
                Image={image[partType]}
                killLine={killLine}
                chokeLine={chokeLine}
                isKillVerticalLine={i < lastKillLineIndex}
                isChokeVerticalLine={i < lastChokeLineIndex}
             />
            )})
          }
          
          </div>
        </div>
        <table className="bopTable bop">
          <thead style={{ display: 'table-header-group' }}>
            <tr>
              <th/> 
              <th/>
              <th>Component <br/> (Name)</th>
              <th>Kill <br/> Line</th>
              <th>Choke <br/> Line</th>
              <th>Last Test Date</th>
              {isFloater() ? (<th>Top<br/>Mid<br/>From Datum ({getLabel('lengthMedium', locationState)})</th>) : null} 
              <th>Top<br/>Mid<br/>RKB Depth({getLabel('lengthMedium', locationState)})</th>
              <th>Hang Off<br/>Mid Pipe<br/>Stick Up{hideLowered ? null : (<><br/>(Raised)</>)} ({getLabel('lengthMedium', locationState)})</th>
              {hideLowered ? null : (<th>Hang Off<br/>Mid Pipe<br/>Stick Up<br/>(Lowered) ({getLabel('lengthMedium', locationState)})</th>)}
            </tr>
          </thead>
          <tbody style={{ overflowY: 'hidden' }}>
            <PartRow 
            part={BOP?.newPart} 
            i='-1' 
            key='new'/>
            
            {(() => {
              return BOP?.bop.parts.map((part, i) => (
                <PartRow 
                part={part} 
                i={i} key={i} 
                derivedData={derivedData} 
                computedData={computedData}/>
              )
              )
            })()}
          </tbody>
        </table>
      </div>
    )
  })

  return (
    <>
      <SectionTabs />
      <Paper>
        <BOPControls />
        <BOPTable/>
      </Paper>
    </>
  )
})

export default BOP
