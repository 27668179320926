import { FunctionComponent, SVGProps } from 'react'
import './Part.css'


type BOPTowerProps = {
  Image: FunctionComponent<SVGProps<SVGSVGElement>>,
  killLine : boolean,
  chokeLine: boolean,
  isKillVerticalLine: boolean,
  isChokeVerticalLine: boolean,
}


const BOPTowerPart = ({...props}: BOPTowerProps) => {
  const { 
    Image,
    killLine, 
    chokeLine, 
    isKillVerticalLine, 
    isChokeVerticalLine,
    } = props
  
  return (
    <div className="bop-part-grid">
      <div className={`${"bop-part-left-side"} ${""}` }>
        <div className={`${"bop-part-left-top"} ${killLine ? "bop-part-horizontal-line bop-part-vertical-line-left" : ""} ${isKillVerticalLine ? "bop-part-vertical-line-left" : ''} `}></div>
        <div className={`${"bop-part-left-bottom"} ${isKillVerticalLine ? "bop-part-vertical-line-left" : ''} ` }></div>
      </div>
      <div className={`${"part_container_bop"}
      ${killLine ? "part_connector_kill-line" : ""}`}>
        <Image className="part_image_bop"  />
      </div>
      <div className="bop-part-right-side">
         <div className={`${"bop-part-right-top"} ${chokeLine ? "bop-part-horizontal-line bop-part-vertical-line-right" : ""} ${isChokeVerticalLine ? "bop-part-vertical-line-right" : ''} `}></div>
        <div className={`${"bop-part-right-bottom"} ${isChokeVerticalLine ? "bop-part-vertical-line-right" : ''}`}></div>
      </div>
    </div>
  )
}

export default BOPTowerPart
