import { useContext, useEffect, useState, useCallback } from 'react'
import { UserContext, CompanyContext, RigContext } from 'App'
import { useQuery, gql } from '@apollo/client'
import iconCaretDown from '../../../assets/images/icons/icon_caretDown.svg'
import { displayNameFromRigType } from '../../../utils/helpers'

const Rig = () => {
    const { user } = useContext(UserContext)
    const { company } = useContext(CompanyContext)
    const { rig, setRig } = useContext(RigContext)
    const [scope, setScope] = useState('')

    const { data, loading, refetch } = useQuery(
        gql`
            query Rig($companyId: String!) {
                rigsByCompanyId(companyId: $companyId) {
                    id
                    name
                    type
                }
            }
        `,
        {
            variables: {
                companyId: company?.id ? company?.id : '-'
            },
            onError(err) {
                console.error(err)
            }
        }
    )

    useEffect(() => {
        const operator = ['rig']
        const shorebased = ['rig', 'company']
        const support = ['rig', 'company', 'global']

            switch (JSON.stringify(user?.scope)) {
            case JSON.stringify(operator):
                setScope('operator')
                break
            case JSON.stringify(shorebased):
                setScope('shorebased')
                break
            case JSON.stringify(support):
                setScope('support')
                break
            default:
                setScope('operator')
                break
        }
    }, [user?.scope])

    function handleChange(event) {
        setCurrentRig(event.target.value)
    }

    const setCurrentRig = useCallback((rigId) =>  {
        const thisRig = data.rigsByCompanyId.find((r) => r.id === rigId)
        if(! thisRig) {
            console.log("Rig id " + rigId + " not found in data")
            return;
        }

        const context = JSON.parse(window.localStorage.getItem('context'))
        context.rig.id = thisRig.id
        context.rig.name = thisRig.name
        context.rig.type = thisRig.type
        window.localStorage.setItem('context', JSON.stringify(context))

        setRig && setRig(context.rig)
    }, [data?.rigsByCompanyId, setRig ]);

    const ensureRigSelected = useCallback(() => {
        if(! (data?.rigsByCompanyId && data.rigsByCompanyId.length > 0)) return;

        if(data.rigsByCompanyId.find(r => r.id === rig?.id)) return;

        if(user?.scope === 'operator') {
            if(user?.rigId) setCurrentRig(user.rigId)
        } else {
            setCurrentRig(data.rigsByCompanyId[0].id)
        }
    }, [data?.rigsByCompanyId,rig?.id, setCurrentRig, user?.rigId, user?.scope]);

    useEffect(() => {
        refetch()
        ensureRigSelected()
    }, [company, ensureRigSelected, refetch])


    return (
        <>
            {!loading && ((scope === 'shorebased') || (scope === 'support')) ? (
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <img alt="Rig" src={iconCaretDown} />
                    </div>
                    <div style={{ marginLeft: '-8px' }}>
                        <select
                            id="currentRig"
                            onChange={handleChange}
                            value={
                                rig?.id
                            }
                            title="Select a Rig"
                            className="dashboardSelect"
                            style={{ height: '30px !important', backgroundColor: 'transparent' }}
                        >
                            {data?.rigsByCompanyId.map(({ id, name, type }) => (
                                <option key={id} value={`${id}`}>
                                    {name + ' (' + displayNameFromRigType(type, true) + ')' } 
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className="dashboardSelect">
                    {rig?.name}
                </div>
            )}
        </>
    );
}

export default Rig
